<script lang="ts" setup>
import type { NotificationMessage, NotificationMessagePayload } from "@/types";
const { isDesktop } = useDevice();
const { t } = useT();
const { initAndOpen } = useSupportChat();

const props = defineProps<{
	unreadNotifications: NotificationMessage[];
	readNotifications: NotificationMessage[];
}>();
const emit = defineEmits<{
	(event: "closePopup"): void;
	(event: "setStatusRead", payload: number[]): void;
	(event: "updateNotificationStatus", payload: number[]): void;
	(event: "clickOnMessage", payload: NotificationMessagePayload): void;
}>();

const hasMessages = computed(() => props.unreadNotifications.length || props.readNotifications.length);

const handleClickOpenSupport = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "widget",
		button_name: "support"
	});
	initAndOpen();
};

const handleClickMessage = (payload: NotificationMessagePayload) => {
	emit("clickOnMessage", payload);
};

onMounted(() => {
	if (!isDesktop) {
		document.body.style.overflow = "hidden";
	}

	dispatchGAEvent({
		event: "tooltips",
		type: props.unreadNotifications.length ? "new" : "empty",
		location: "header",
		form_name: "notifications"
	});

	if (!props.unreadNotifications.length) {
		return;
	}

	const unreadOfferIds = props.unreadNotifications.reduce<number[]>((acc, item) => {
		if (item.type === "offer" && new Date(item.dateEnd).getTime() < new Date().getTime()) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadOfferIds.length) {
		emit("setStatusRead", unreadOfferIds);
	}
});
onUnmounted(() => {
	document.body.style.overflow = "";
	if (!props.unreadNotifications.length) {
		return;
	}

	const unreadIds = props.unreadNotifications.reduce<number[]>((acc, item) => {
		if (!item.link) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadIds.length) {
		emit("updateNotificationStatus", unreadIds);
	}
});
</script>
<template>
	<APopper show>
		<template #content>
			<div class="notification-popup">
				<div class="header">
					<AText variant="toledo" :modifiers="['extrabold']">
						{{ t("InBox") }}

						<AText
							v-if="unreadNotifications.length"
							:modifiers="['bold']"
							class="text-coventry unread-count text-tlalnepantla"
						>
							{{ unreadNotifications.length }}
						</AText>
					</AText>
					<div>
						<NuxtIcon class="close-icon" name="20/close" filled @click.stop="$emit('closePopup')" />
					</div>
				</div>

				<div class="main-content">
					<template v-if="hasMessages">
						<div v-if="unreadNotifications.length" class="unread-wrapper text-cannes">
							<AText variant="tempe" class="title" as="p" :modifiers="['semibold']">{{ t("New") }}</AText>
							<MNotificationCenterItem
								v-for="item in unreadNotifications"
								:key="`unread-${item.ncMessageId}`"
								:data="item"
								@click-message="handleClickMessage"
							/>
						</div>
						<div v-if="readNotifications.length" class="readed-wrapper text-cannes">
							<AText variant="tempe" class="title" as="p" :modifiers="['semibold']">{{ t("Earlier") }}</AText>
							<MNotificationCenterItem
								v-for="item in readNotifications"
								:key="`read-${item.ncMessageId}`"
								:data="item"
								@click-message="handleClickMessage"
							/>
						</div>
					</template>
					<AText v-else as="div" class="no-messages text-chanda" variant="toledo" :modifiers="['center', 'semibold']">
						{{ t("Here will be your messages") }}
					</AText>
				</div>

				<div class="footer">
					<AText class="text-chanda" as="div" variant="tempe" :modifiers="['semibold']">
						{{ t("Messages older than 14 days are deleted for you.") }}
					</AText>
					<AText
						variant="tempe"
						class="text-coventry footer__support"
						:modifiers="['capitalize', 'bold']"
						@click="handleClickOpenSupport"
					>
						{{ t("Customer care") }}
					</AText>
				</div>
			</div>
		</template>
	</APopper>
</template>
<style scoped lang="scss">
.notification-popup {
	width: 370px;
	max-height: 594px;
	padding: 0 gutter(3);
	cursor: auto;

	@include media-breakpoint-down(lg) {
		width: 100%;
		max-height: none;
	}

	&:deep(svg) {
		filter: none !important;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: gutter(2) 0;
		line-height: 24px;
	}

	.unread-count {
		margin-left: gutter(1);
	}

	.readed-wrapper {
		margin-top: gutter(2);
	}

	.close-icon {
		font-size: 24px;
		line-height: 1;
		margin-left: gutter(2);
		cursor: pointer;

		&:deep(svg) {
			margin-bottom: 0;
		}
	}

	.main-content {
		max-height: 488px;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--clara);
			border-radius: 4px;
			width: 4px;
		}

		.title {
			margin-bottom: gutter(1.2);
		}

		@media (orientation: landscape) {
			max-height: 100%;
		}
	}

	.no-messages {
		padding: gutter(2);
		p {
			margin-top: gutter(0.5);
		}
	}

	.footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: gutter(1);
		padding: gutter(2) 0;

		&__support {
			white-space: nowrap;
			text-decoration: underline;

			&:hover {
				cursor: pointer;
				text-decoration: none;
			}
		}
	}
}
</style>
